import tw, { styled, TwStyle } from "twin.macro";
import { useState, useCallback, useRef, useEffect } from "react";

import { Tag, Label } from "../../clients/apiClient";
import { Icon } from "../generic";
import { BaseProps } from "../generic/BaseProps";

export interface LabelItemProps {
  isHighlight?: boolean;
  onDelete?: () => void;
}

export function LabelItem(props: BaseProps & LabelItemProps) {
  return (
    <li
      className={props.isHighlight ? "bg-highlight-light" : ""}
      tw="lbl cursor-default inline-block"
    >
      {props.children}
      {props.onDelete ? (
        <Icon
          tw="cursor-pointer absolute right-1.5"
          t="close"
          onClick={props.onDelete}
        />
      ) : null}
    </li>
  );
}

export interface TagItemProps {
  isHighlight?: boolean;
  isInput?: boolean;
  tag?: Tag;
  onCreate?: (update: { key: string; value: string }) => Promise<void>;
  onUpdate?: (
    tag: { key: string; value: string },
    update: { key: string; value: string },
  ) => void;
  onDelete?: (tag?: { key: string; value: string }) => void;
  autoFocus?: boolean;
}

export function TagItem({
  onClick,
  isHighlight,
  isInput,
  tag,
  onUpdate,
  onCreate,
  onDelete,
  autoFocus,
}: BaseProps & TagItemProps): JSX.Element {
  const [key, setKey] = useState<string>(tag?.key || "");
  const [value, setValue] = useState<string>(tag?.value || "");
  const [isEditValue, setEditValue] = useState<boolean>(false);
  const [isEditKey, setEditKey] = useState<boolean>(false);
  const isEmptyState = !isEditKey && !key;

  const inputRef = useRef<HTMLLIElement>(null);

  const handleChangeKey = useCallback(
    (evt) => {
      let newKey = evt.target.value;
      if (newKey?.includes(":")) {
        newKey = newKey.replace(":", "");
        setEditValue(true);
        setEditKey(false);
      }
      if (newKey?.trim() === "#") {
        newKey = "#";
        setEditValue(true);
        setEditKey(false);
      }
      setKey(newKey);
    },
    [setKey, setEditValue, setEditKey],
  );

  const activateEditMode = useCallback(() => {
    if (onUpdate || onCreate) {
      if (!key) {
        setEditKey(true);
      } else {
        // FIXME click while editing key
        setEditValue(true);
      }
    }
  }, [key, setEditKey, setEditValue, onUpdate, onCreate]);

  const handleCancel = useCallback(
    (evt?: any) => {
      evt?.stopPropagation();
      setEditKey(false);
      setEditValue(false);
      setValue(tag?.value || "");
      setKey(tag?.key || "");
    },
    [setValue, setKey, tag?.value, tag?.key],
  );

  const handleInputKey = useCallback(
    (evt: any) => {
      if (evt.keyCode === 13) {
        setEditValue(true);
        setEditKey(false);
      }
    },
    [setEditValue, setEditKey],
  );

  const handleKeyPress = useCallback(
    (evt) => {
      if (evt.key === "Escape") {
        handleCancel();
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    [handleCancel],
  );

  useEffect(() => {
    if (inputRef?.current) {
      const node = inputRef.current;
      node.addEventListener("keydown", handleKeyPress);
      return () => {
        node.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleKeyPress, inputRef]);

  const handleRemove = useCallback(
    (evt) => {
      evt?.stopPropagation();
      if (onDelete) {
        onDelete(tag);
      }
    },
    [onDelete, tag],
  );

  /*
  useEffect(() => {
    inputRef.current?.focus();
  }, [isEditValue]);
  */

  function handleInputValue(evt: any) {
    if (evt.keyCode === 13) {
      // return key
      saveNewAttribute();
    } else if (evt.key === "Escape") {
      // Escape key
      handleCancel();
    }
  }
  function handleChangeValue(event: any) {
    setValue(event.target.value);
  }

  const saveNewAttribute = useCallback(
    async function () {
      if (key && value) {
        if (tag && onUpdate) {
          await onUpdate(tag, { key, value });
          setKey(tag?.key || "");
          setValue(tag?.value || "");
          setEditValue(false);
          setEditKey(!tag?.key);
        } else if (onCreate) {
          await onCreate({ key, value });
          setKey("");
          setValue("");
          setEditValue(false);
          setEditKey(true);
        }
      }
    },
    [
      key,
      value,
      tag,
      onUpdate,
      onCreate,
      setKey,
      setValue,
      setEditKey,
      setEditValue,
    ],
  );

  return (
    <li tw="relative" onClick={activateEditMode} ref={inputRef}>
      <span
        className={
          (onUpdate ? "cursor-text" : "cursor-default") +
          (!isEmptyState && " border border-grey shadow-lg")
        }
        tw="inline-block bg-white relative"
      >
        {isEditKey && (
          <span tw="inline-grid grid-cols-1">
            <input
              size={8}
              tw="tag-key text-grey-dark"
              placeholder="Enter key"
              onKeyUp={handleInputKey}
              onChange={handleChangeKey}
              value={key}
              autoFocus
            />
            <span tw="tag-key py-0 h-0 overflow-hidden whitespace-pre-wrap">
              {key}
            </span>
          </span>
        )}
        {!isEditKey && key ? (
          <span
            className={isHighlight ? "bg-highlight-lighter" : ""}
            tw="tag-key text-grey-dark"
          >
            {key}
            {key !== "#" && ": "}
          </span>
        ) : null}
        {isEmptyState ? (
          <span tw="tag-val text-grey-dark">
            <i className="mi-add text-md pr-1" />
            Add tag
          </span>
        ) : null}
        {isEditValue && (
          <span tw="inline-grid grid-cols-1">
            <input
              size={9}
              value={value}
              tw="tag-val"
              placeholder="Enter value"
              onKeyUp={handleInputValue}
              onChange={handleChangeValue}
              autoFocus
            />
            <span tw="tag-val py-0 h-0 overflow-hidden whitespace-pre-wrap">
              {value}
            </span>
          </span>
        )}
        {!isEditValue && value ? (
          <span
            className={isHighlight ? "bg-highlight-light" : ""}
            tw="tag-val"
          >
            {value}
          </span>
        ) : null}
      </span>
      <Collapsible
        h={isEditKey || isEditValue ? "86px" : "0"}
        tw="flex flex-col items-end space-y-1 pr-1"
      >
        <ContextButton
          i="mi-check"
          disabled={!value || value === tag?.value}
          onClick={saveNewAttribute}
        >
          Save
        </ContextButton>
        <ContextButton i="mi-close" onClick={handleCancel}>
          Cancel
        </ContextButton>
        {tag ? (
          <ContextButton i="mi-delete" tw="text-danger" onClick={handleRemove}>
            Remove
          </ContextButton>
        ) : null}
      </Collapsible>
    </li>
  );
}

export const collapsibleSizes: { [key: string]: TwStyle } = {
  "86px": tw`h-[86px]`,
  "0": tw`h-0`,
};

export const Collapsible = styled.div(({ h }: { h: string }) => [
  collapsibleSizes[h],
  tw`overflow-hidden transition-all`,
]);

export function ContextButton(props: {
  disabled?: any;
  i?: string;
  className?: string;
  children?: string | JSX.Element;
  onClick?: (_: any) => void;
}): JSX.Element {
  return (
    <button
      onClick={props.onClick}
      className={props.className}
      tw="disabled:(text-grey-dark cursor-default)"
      disabled={props.disabled}
    >
      {props.i ? <i tw="pr-1" className={props.i} /> : null}
      <span tw="text-sm">{props.children}</span>
    </button>
  );
}

export interface ItemSelection {
  item: Tag | Label;
  index: number;
  type: "tag" | "label";
}

export interface ItemGroupProps {
  tags?: Tag[];
  labels?: Label[];
  onSelect?: (selection: ItemSelection) => void;
}

export const ItemGroupContainer = styled.ul(
  ({ editMode }: { editMode?: boolean }) => [
    tw`flex flex-wrap gap-2 transition-all border border-transparent items-start`,
    editMode && tw`border-grey m-[-6px] p-[6px]`,
  ],
);

export function ItemGroup({
  tags,
  labels,
  children,
  onSelect,
}: BaseProps & ItemGroupProps) {
  const [, /*selectState*/ setSelectState] = useState({});
  const handleSelect = useCallback(
    (type: "tag" | "label", index: number, item: Tag | Label) => {
      setSelectState({ type, index, item });
      if (onSelect) {
        onSelect({ type, index, item });
      }
    },
    [onSelect],
  );

  return (
    <ItemGroupContainer>
      {tags &&
        tags.map((t, idx) => (
          <TagItem tag={t} onClick={() => handleSelect("tag", idx, t)} />
        ))}
      {labels &&
        labels.map((l, idx) => (
          <LabelItem onClick={() => handleSelect("label", idx, l)}>
            {l}
          </LabelItem>
        ))}
      {children}
    </ItemGroupContainer>
  );
}

export interface LabelsProps {
  labels?: Label[];
  onCreate?: (value: string) => void;
  onDelete?: (value: string) => void;
}

export function Labels({
  labels,
  onDelete,
  onCreate,
  className,
}: BaseProps & LabelsProps): JSX.Element {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    function handleClick() {
      setEditMode(false);
    }

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [editMode, setEditMode]);

  const handleInput = useCallback(
    (evt) => {
      if (evt.keyCode === 13) {
        evt.preventDefault();
        onCreate && onCreate(evt.target.value);
        // FIXME empty input on success
      }
    },
    [onCreate],
  );

  const handleEnterEditMode = useCallback(
    (evt) => {
      evt.stopPropagation();
      setEditMode(true);
    },
    [setEditMode],
  );

  return (
    <div className={className || undefined}>
      <ItemGroupContainer
        tw="relative"
        editMode={editMode}
        onClick={handleEnterEditMode}
        className={editMode ? "editMode" : ""}
      >
        {labels?.map((l) => (
          <LabelItem
            key={l}
            onDelete={editMode && onDelete ? () => onDelete(l) : undefined}
          >
            {l}{" "}
          </LabelItem>
        ))}
        {onCreate ? (
          <input
            tw="lbl bg-white hover:bg-grey-lightest active:bg-grey-lightest w-32 leading-[1.25rem]"
            placeholder="+ Add label"
            onKeyUp={handleInput}
          />
        ) : null}
        {onDelete && !editMode ? (
          <Icon
            t="edit"
            tw="text-grey-light text-2xl absolute bottom-0 right-0 cursor-pointer"
          />
        ) : null}
      </ItemGroupContainer>
    </div>
  );
}
