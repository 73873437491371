import "twin.macro";

import React from "react";
import { Link } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { Asset } from "../../clients/apiClient";

export interface AssetTileProps {
  asset: Asset;
}

function AssetTile(props: AssetTileProps): JSX.Element | null {
  const asset = props.asset;

  return (
    <div>
      <Link to={asset.link} tw="min-h-40 inline-block">
        {asset.url ? (
          <LazyLoadImage
            src={`${asset.url}?w=350&h=350`}
            alt={asset.url}
            tw="rounded-md"
          />
        ) : (
          asset.name
        )}
      </Link>
    </div>
  );
}

export default React.memo(AssetTile);
