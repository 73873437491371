import "twin.macro";

import { useEffect } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";

import { useWorkspace } from "../../clients/apiHooks";

import { ListLink } from "./ListLink";

function CollectionList(): JSX.Element {
  const [collections, loading, err] = useWorkspace();
  const navigate = useNavigate();
  const routeMatch = useMatch({
    path: "/",
  });

  useEffect(() => {
    if (collections && collections.length && routeMatch) {
      navigate(`/collection/${collections[0].id}`, { replace: true });
    }
  }, [collections, navigate, routeMatch]);

  return (
    <div tw="p-4">
      <span tw="label">Collections</span>
      <Link to="/collection/new-collection">
        <button tw="my-2">+ New collection</button>
      </Link>
      {loading && <div>loading collections...</div>}
      {err && (
        <div>sorry, something went wrong fetching the collections...</div>
      )}
      <div tw="divide-y divide-solid">
        {collections &&
          collections.map((c) => (
            <ListLink
              key={c.id}
              name={c.name}
              href={`/collection/${c.id}`}
              tw="py-2"
            />
          ))}
      </div>
    </div>
  );
}

export default CollectionList;
