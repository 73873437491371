import { FileUpload, UploadStatus } from "../atoms";
import { SortOrder } from "../components/collection/AlbumList";

declare var __API_ROOT__: string;

export function apiUrl(path: string, prefix: string = "/api") {
  const apiRoot = (typeof __API_ROOT__ !== "undefined" && __API_ROOT__) || "";
  const normPath = `${path.startsWith("/") ? "" : "/"}${path}`;
  return `${apiRoot}${prefix}${normPath}`;
}

async function apiFetch(
  path: string,
  config?: RequestInit & { headers?: Record<string, string> },
) {
  const token = localStorage.getItem("token");
  const headers: Record<string, string> = { ...config?.headers };
  if (token) {
    headers["token"] = token;
  }
  return await fetch(apiUrl(path), {
    ...config,
    headers,
  }).then(async (response) => {
    if (response.status >= 200 && response.status < 300) {
      try {
        return await response.json();
      } catch (error) {
        // console.error("error parsing json return", error) // to be expected for delete statements
        return response;
      }
    } else {
      throw new Error(`Something went wrong when calling ${path}`);
    }
  });
}

export async function uploadFile(fileUpload: FileUpload) {
  if (fileUpload.state === UploadStatus.SUCCESS) {
    return; //we already tried to upload this before, skip.
  }
  const formData = new FormData();
  formData.append("upload", fileUpload.file);

  return apiFetch(
    `/collections/${fileUpload.collectionId}/albums/${fileUpload.albumId}/assets`,
    {
      method: "POST",
      body: formData,
    },
  );
}

export interface CollectionBase {
  name: string;
  id: string;
}

export type Workspace = CollectionBase[];

export async function getWorkspace(): Promise<Workspace> {
  const result = await apiFetch(`/collections`);
  return result.map((d: [string, string]) => {
    return {
      name: d[0],
      id: d[1],
    };
  });
}

export interface Album {
  name: string;
  id: string;
  collectionId: string;
  labels: Label[];
  tags: Tag[];
}

export interface Share {
  name: string;
  id: string;
}

export interface PatchAlbumParameters {
  labels?: Label[];
  tags?: Tag[];
  name?: string;
}

export interface PatchCollectionParameters {
  name: string;
  collectionId: string;
}

export interface PatchShareParameters {
  name: string;
  shareId: string;
}

export interface InviteToShareParameters {
  emailStr: string;
  shareId: string;
}

export interface Collection {
  name: string;
  id: string;
}

export interface AlbumsResult {
  albums: Album[];
  albumsCount: number;
}

export interface AssetsResult {
  assets: Asset[];
  assetsCount: number;
}

export type SharesResult = Share[];

function toAlbum(collectionId: string, a: any): Album {
  return {
    name: a.name,
    id: a.id,
    collectionId,
    labels: a.labels || [],
    tags: a.tags?.map((t: any) => ({ key: t[0], value: t[1] })) || [],
  };
}

export async function getAlbum(
  collectionId: string,
  albumId: string,
): Promise<Album> {
  const a = await apiFetch(
    `/collections/${collectionId}/albums/${encodeURIComponent(albumId)}`,
  );
  return toAlbum(collectionId, a);
}

export async function getAlbums(
  collectionId: string,
  limit: number,
  offset: number,
  searchQuery: string,
  sortOrder: SortOrder,
): Promise<AlbumsResult> {
  let url = `/collections/${collectionId}/albums?limit=${limit}&offset=${offset}&order=${sortOrder}`;
  if (searchQuery) {
    const formattedSearchQuery = formatSearchQuery(searchQuery);
    url += `&${formattedSearchQuery}`;
  }

  const result = await apiFetch(url);
  const albums = result.albums.map((a: any) => toAlbum(collectionId, a));

  return { albums, albumsCount: result.num_results };
}

export async function getCollectionTagsKeys(
  collectionId: string,
  prefix: string,
) {
  const url = `/collections/${collectionId}/tags/keys?prefix=${prefix}`;
  const result = await apiFetch(url);

  return result;
}

export async function getCollectionTagsValues(
  collectionId: string,
  key: string,
  prefix: string,
) {
  const encodedKey = encodeURIComponent(key);
  const encodedPrefix = encodeURIComponent(prefix);
  const url = `/collections/${collectionId}/tags/values?key=${encodedKey}&prefix=${encodedPrefix}`;
  const result = await apiFetch(url);

  return result;
}

export async function createAlbum(
  collectionId: string,
  name: string,
): Promise<Album> {
  const newPayload = { name, tags: [], labels: [] };
  return apiFetch(`/collections/${collectionId}/albums`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(newPayload),
  });
}

export async function deleteAlbum(
  collectionId: string,
  albumId: string,
): Promise<null> {
  await apiFetch(`/collections/${collectionId}/albums/${albumId}`, {
    method: "DELETE",
  });
  return null;
}

export interface Asset {
  id: string;
  name: string;
  collection_id: string;
  album_id: string;
  path: string | null;
  download_path: string;
  url: string | null;
  mime_type: string;
  labels: Label[];
  tags: Tag[];
  album_labels?: Label[];
  album_tags?: Tag[];
  link: string;
}

export interface Tag {
  key: string;
  value: string;
}

export type Label = string;

export interface AlbumWithAssets {
  id: string;
  name: string;
  collectionId: string;
  assets: Asset[];
  labels: Label[];
  tags: Tag[];
}

function toAsset(asset: any, collectionId: string): Asset {
  return {
    ...asset,
    collectionId,
    url: asset.path && apiUrl(asset.path, ""),
    link: `/collection/${collectionId}/a/${asset.album_id}/s/${asset.id}`,
    tags: asset.tags.map((t: [string, string]) => ({
      key: t[0],
      value: t[1],
    })),
  };
}

const formatSearchQuery = (searchQuery: string): string => {
  let tags: string[] = [];
  let queries: string[] = [];

  const splitedQueries = searchQuery.trim().split(/\s+/);
  splitedQueries.forEach((query) => {
    if (query.includes("!=") || query.includes("=") || query.startsWith("#")) {
      tags.push(query);
    } else {
      queries.push(query);
    }
  });

  const encodedTags = tags.map((tag) => `tag=${encodeURIComponent(tag)}`);
  const encodedQueries = queries.map(
    (query) => `query=${encodeURIComponent(query)}`,
  );

  const formattedSearchQuery = [...encodedTags, ...encodedQueries].join("&");

  return formattedSearchQuery;
};

export async function getAssets(
  collectionId: string,
  albumId: string | undefined,
  searchQuery: string | undefined,
  limit: number,
  offset: number,
  sortOrder: SortOrder = "ASC",
): Promise<AssetsResult> {
  let url = `/collections/${collectionId}/assets?order=${sortOrder}&limit=${limit}&offset=${offset}`;
  if (albumId) {
    url += `&album=${albumId}`;
  }
  if (searchQuery) {
    const formattedSearchQuery = formatSearchQuery(searchQuery);

    url += `&${formattedSearchQuery}`;
  }
  const assetResponse = await apiFetch(url);

  const assets = assetResponse.assets.map((asset: any) =>
    toAsset(asset, collectionId),
  );
  return { assets, assetsCount: assetResponse.num_results };
}

export async function getAsset(
  collectionId: string,
  albumId: string,
  assetId: string,
): Promise<Asset> {
  const url = `/collections/${collectionId}/albums/${albumId}/assets/${assetId}`;
  const asset = await apiFetch(url);
  return toAsset(asset, collectionId);
}

export async function updateAssetMetadata(
  newValues: { labels?: Label[]; tags?: Tag[]; name?: String },
  collectionId: string,
  albumId: string,
  assetId: string,
) {
  const newTags = newValues.tags?.map((t) => [t.key, t.value]);

  const newPayload = {
    labels: newValues.labels,
    tags: newTags,
    name: newValues.name,
  };
  return apiFetch(
    `/collections/${collectionId}/albums/${albumId}/assets/${assetId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(newPayload),
    },
  );
}

export async function deleteAsset(
  collectionId: string,
  albumId: string,
  assetId: string,
): Promise<null> {
  await apiFetch(
    `/collections/${collectionId}/albums/${albumId}/assets/${assetId}`,
    { method: "DELETE" },
  );
  return null;
}

export async function createCollection(name: string): Promise<CollectionBase> {
  const newPayload = { name: name };
  return await apiFetch(`/collections`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(newPayload),
  });
}

export async function patchCollection(
  name: string,
  collectionId: string,
): Promise<CollectionBase> {
  const newPayload = { name: name };
  return await apiFetch(`/collections/${collectionId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(newPayload),
  });
}

export async function patchAlbum(
  collectionId: string,
  albumId: string,
  newValues: PatchAlbumParameters,
) {
  const payload: {
    labels?: string[];
    tags?: [string, string][];
    name?: string;
  } = {};
  if (newValues.tags) {
    payload.tags = newValues.tags.map(({ key, value }) => [key, value]);
  }
  if (newValues.labels) {
    payload.labels = newValues.labels;
  }
  if (newValues.name) {
    payload.name = newValues.name;
  }
  return apiFetch(`/collections/${collectionId}/albums/${albumId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PATCH",
    body: JSON.stringify(payload),
  });
}

export async function createShare(name: string): Promise<Share> {
  const newPayload = { name };
  return await apiFetch(`/shares`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(newPayload),
  });
}

export async function patchShare(
  name: string,
  shareId: string,
): Promise<CollectionBase> {
  const payload = { name };
  return await apiFetch(`/shares/${shareId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PATCH",
    body: JSON.stringify(payload),
  });
}

export async function getShares(): Promise<SharesResult> {
  return await apiFetch(`/shares`);
}

export async function inviteToShare(emailStr: string, shareId: string) {
  const emails = emailStr
    .trim()
    .split(/[\s,]+/)
    .filter((e) => e.includes("@"));

  return await apiFetch(`/shares/${shareId}/invite`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(emails),
  });
}
