import tw from "twin.macro";
import { useState, useCallback, useRef, useEffect } from "react";

import { Collapsible, ContextButton } from "../tags/Items";
import { useShare } from "../../clients/apiHooks";

export function InviteEmailInput({
  shareId,
}: {
  shareId?: string;
}): JSX.Element {
  const [emailStr, setEmailStr] = useState<string>("");
  const [isEditing, setEditing] = useState<boolean>(false);
  const isEmptyState = !isEditing && !emailStr;
  const { inviteToShare } = useShare(shareId);

  const inputRef = useRef<HTMLLIElement>(null);

  const activateEditMode = useCallback(() => {
    if (!emailStr) {
      setEditing(true);
    }
  }, [emailStr]);

  const handleCancel = useCallback(
    (evt?: any) => {
      evt?.stopPropagation();
      setEditing(false);
      setEmailStr("");
    },
    [setEmailStr],
  );

  const handleKeyPress = useCallback(
    (evt) => {
      if (evt.key === "Escape") {
        handleCancel();
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    [handleCancel],
  );

  useEffect(() => {
    if (inputRef?.current) {
      const node = inputRef.current;
      node.addEventListener("keydown", handleKeyPress);
      return () => {
        node.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleKeyPress, inputRef]);

  function handleChange(event: any) {
    setEmailStr(event.target.value);
  }

  const sendInvites = useCallback(
    async function () {
      if (emailStr && shareId) {
        await inviteToShare({ emailStr, shareId });
        setEmailStr("");
        setEditing(false);
      }
    },
    [emailStr, shareId, inviteToShare],
  );

  const handleInput = useCallback(
    (evt: any) => {
      if (evt.keyCode === 13) {
        // return key
        sendInvites();
      } else if (evt.key === "Escape") {
        // Escape key
        handleCancel();
      }
    },
    [handleCancel, sendInvites],
  );

  return (
    <li tw="relative" onClick={activateEditMode} ref={inputRef}>
      <span
        className={
          "cursor-default" + (!isEmptyState && " border border-grey shadow-lg")
        }
        tw="inline-block bg-white relative"
      >
        {isEditing && (
          <span tw="inline-grid grid-cols-1">
            <input
              size={12}
              tw="tag-key text-grey-dark"
              placeholder="Enter emails"
              onKeyUp={handleInput}
              onChange={handleChange}
              value={emailStr}
              autoFocus
            />
            <span tw="tag-key py-0 h-0 overflow-hidden whitespace-pre-wrap">
              {emailStr}
            </span>
          </span>
        )}
        {!isEditing && emailStr ? (
          <span tw="tag-key text-grey-dark">{emailStr}</span>
        ) : null}
        {isEmptyState ? (
          <span tw="tag-val text-grey-dark">
            <i className="mi-add text-md pr-1" />
            Add
          </span>
        ) : null}
      </span>
      <Collapsible
        h={isEditing ? "86px" : "0"}
        tw="flex flex-col items-end space-y-1 pr-1"
      >
        <ContextButton i="mi-check" disabled={!emailStr} onClick={sendInvites}>
          Invite
        </ContextButton>
        <ContextButton i="mi-close" onClick={handleCancel}>
          Cancel
        </ContextButton>
      </Collapsible>
    </li>
  );
}
