import React, { Suspense, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useCollection } from "../../clients/apiHooks";

import AlbumList from "./AlbumList";
import AlbumView from "../album/AlbumView";
import NewAlbumView from "../album/NewAlbumView";
import AssetDetails from "../album/AssetDetails";

import { Modal } from "../generic";
import { BackButton } from "../generic/Neu";
import ErrorBoundary from "../generic/ErrorBoundary";
import { EditableTitle } from "../album/EditableTitle";
import CollectionList from "../navigation/CollectionList";
import { Helmet } from "react-helmet";
import ShareList from "../navigation/ShareList";
import { MainCol, MarginWrapper, MenuCol } from "../generic/Layout";
import tw, { styled } from "twin.macro";

const AlbumCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all ease-out relative`,
  props.active ? tw`w-full sm:w-[80%]` : tw`w-0`,
]);

function CollectionView(): JSX.Element | null {
  const { collectionId, albumId, assetId } = useParams<{
    collectionId: string;
    albumId?: string;
    assetId?: string;
  }>();
  const { collection, patchCollection } = useCollection(collectionId);

  async function handleSubmit(newCollectionName: string) {
    if (!collection) {
      return;
    }
    const newValues = { name: newCollectionName, collectionId: collection.id };
    await patchCollection(newValues);
  }
  const navigate = useNavigate();
  const handleCloseAssetDetails = () => {
    navigate(-1);
  };

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    scrollContainerRef?.current?.scrollTo(0, 0);
  }, [albumId, collectionId, scrollContainerRef]);

  const loc = useLocation();
  const isNewAlbumInput = loc.pathname.endsWith("/new-album");
  const isAlbumView = !!albumId || isNewAlbumInput;
  const isCollectionView = !!collectionId && !albumId && !isNewAlbumInput;

  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the collection...</div>}
    >
      <Helmet>
        <title>
          {collection?.name ? `${collection.name} | Exrepo` : "Exrepo"}
        </title>
      </Helmet>
      {collection && (
        <div tw="max-w-screen-fhd flex mx-auto">
          <MenuCol active={!isAlbumView}>
            <CollectionList />
            <ShareList />
          </MenuCol>

          <MainCol active={isCollectionView}>
            <MarginWrapper isCenter={isCollectionView} hasMenu>
              {collection && (
                <>
                  {isCollectionView && <BackButton tw="mb-8 sm:hidden" />}
                  <span tw="label">Collection</span>
                  <h1 tw="w-full">
                    <EditableTitle
                      tw="sm:mb-4"
                      onUpdate={handleSubmit}
                      title={collection.name}
                    />
                  </h1>
                  <AlbumList
                    collectionId={collection.id}
                    isCondensed={isAlbumView}
                  />
                </>
              )}
            </MarginWrapper>
          </MainCol>

          <AlbumCol active={isAlbumView}>
            {isNewAlbumInput && (
              <MarginWrapper isCenter hasMenu ref={scrollContainerRef}>
                <NewAlbumView />
              </MarginWrapper>
            )}
            {albumId ? (
              <Suspense fallback={<div />}>
                <MarginWrapper isCenter hasMenu ref={scrollContainerRef}>
                  {isAlbumView && <BackButton tw="mb-8" />}
                  <AlbumView />
                </MarginWrapper>
              </Suspense>
            ) : null}
          </AlbumCol>
        </div>
      )}
      {assetId ? (
        <Modal>
          <Suspense fallback={<div />}>
            <AssetDetails
              tw="w-full h-full"
              id={assetId}
              albumId={albumId}
              collectionId={collectionId}
              onSelect={handleCloseAssetDetails}
              onClose={handleCloseAssetDetails}
            />
          </Suspense>
        </Modal>
      ) : null}
    </ErrorBoundary>
  );
}

export default CollectionView;
