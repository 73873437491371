import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "mono-icons/iconfont/icons.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdPartyEmailPassword from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";

import { GlobalStyles } from "twin.macro";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getDomain } from "./utils/getDomain";

const domain = getDomain();

SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
    appName: "Ex-Repo",
    apiDomain: domain,
    websiteDomain: domain,
    apiBasePath: "/auth",
    websiteBasePath: "/login",
  },
  recipeList: [
    ThirdPartyEmailPassword.init({
      signInAndUpFeature: {
        style: `
        [data-supertokens~=headerSubtitle] {
        display: none;
        }
        `,
        providers: [
          // Github.init(),
          // Google.init(),
          // Facebook.init(),
          // Apple.init(),
        ],
      },
      getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return context.redirectToPath;
          }
          if (context.isNewPrimaryUser) {
            // user signed up
          } else {
            // user signed in
          }
          return "/collection/";
        }
      },
    }),
    Session.init(),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <SuperTokensWrapper>
      <App />
    </SuperTokensWrapper>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
