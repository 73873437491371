import "twin.macro";

import { Link } from "react-router-dom";

import { ListLink } from "./ListLink";
import { useShares } from "../../clients/apiHooks";

function ShareList(): JSX.Element {
  const { shares } = useShares();

  return (
    <div tw="p-4">
      <span tw="label">Shares</span>
      <Link to="/share/new-share">
        <button tw="my-2">+ New share</button>
      </Link>
      <div tw="divide-y divide-solid">
        {shares?.length &&
          shares.map((s) => (
            <ListLink
              href={`/share/${s.id}`}
              name={s.name}
              key={s.id}
              tw="py-2"
            />
          ))}
      </div>
    </div>
  );
}

export default ShareList;
