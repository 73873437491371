import tw, { styled } from "twin.macro";

import React from "react";
import { useParams } from "react-router-dom";

import { useShare } from "../../clients/apiHooks";

import { BackButton } from "../generic/Neu";
import ErrorBoundary from "../generic/ErrorBoundary";
import CollectionList from "../navigation/CollectionList";
import { Helmet } from "react-helmet";
import ShareList from "../navigation/ShareList";

import { MenuCol, MarginWrapper } from "../generic/Layout";
import { EditableTitle } from "../album/EditableTitle";
import { InviteEmailInput } from "./SharedWith";
import { ItemGroupContainer } from "../tags/Items";

const ShareCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all ease-out relative`,
  props.active
    ? tw`w-full sm:w-[80%]`
    : tw`hidden sm:block min-w-[250px] w-[20%]`,
]);

function ShareView(): JSX.Element | null {
  const { shareId } = useParams<{ shareId: string }>();
  const { share, patchShare } = useShare(shareId);

  async function updateName(newName: string) {
    if (!share) {
      return;
    }
    await patchShare({ name: newName, shareId: share.id });
  }

  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the share...</div>}
    >
      <Helmet>
        <title>{share?.name ? `${share.name} | Exrepo` : "Exrepo"}</title>
      </Helmet>
      {share && (
        <div tw="max-w-screen-fhd flex mx-auto">
          <MenuCol active>
            <CollectionList />
            <ShareList />
          </MenuCol>
          <ShareCol active={!!shareId}>
            <MarginWrapper isCenter={!!shareId} hasMenu>
              <BackButton tw="mb-8" />
              <div tw="sm:space-y-12">
                <div id="headerDiv" tw="flex flex-row justify-between">
                  <div tw="w-full">
                    <span tw="label">Share</span>
                    <div tw="flex flex-row items-end justify-between w-full">
                      <h1 tw="w-full">
                        <EditableTitle
                          tw="sm:mb-4"
                          onUpdate={updateName}
                          title={share.name}
                        />
                      </h1>
                      {/*<NeuButton*/}
                      {/*  tw="bg-danger"*/}
                      {/*  icon="mi-delete"*/}
                      {/*  disabled={!!sharedWith.length}*/}
                      {/*  onClick={handleDeleteShare}*/}
                      {/*>*/}
                      {/*  Delete Share*/}
                      {/*</NeuButton>*/}
                    </div>
                  </div>
                </div>
                <div tw="space-y-2">
                  <span tw="label mb-4">Shared with:</span>
                  <ItemGroupContainer tw="-ml-1">
                    <InviteEmailInput shareId={shareId} />
                  </ItemGroupContainer>
                </div>
              </div>
            </MarginWrapper>
          </ShareCol>
        </div>
      )}
    </ErrorBoundary>
  );
}

export default ShareView;
