import "twin.macro";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  Album,
  AlbumsResult,
  AssetsResult,
  getAlbums,
  getAssets,
} from "../../clients/apiClient";
import {
  albumAssetPreviewSelector,
  albumSelector,
  useAlbumsRefresh,
  useAssetsRefresh,
} from "../../clients/apiHooks";

import { useIntersectionObserver } from "../../hooks";
import { MenuContainer, NeuButton, CheckboxRoundButton } from "../generic/Neu";
import AssetTile from "../album/AssetTile";
import { getPage, Pagination } from "../generic/Pagination";
import SearchInput from "../generic/SearchInput";

export interface AlbumListProps {
  collectionId: string;
  filter?: string;
  isCondensed?: boolean;
}

export const NoMatchesMessage = ({ searchQuery }: { searchQuery: string }) => (
  <p>The search returned no matches for "{searchQuery}"</p>
);

export type SortOrder = "ASC" | "DESC";

export const renderInputComponent = (
  inputProps: React.InputHTMLAttributes<HTMLInputElement>,
): JSX.Element => (
  <div tw="relative">
    <i className="mi-search absolute top-2 left-2" />
    <input
      {...inputProps}
      tw="pl-8 pr-2 w-full h-12 -mt-1 rounded focus:outline-none bg-[#fafafa] border border-[#a8a8a8]"
      placeholder="Search"
    />
  </div>
);

const pageSize = 20;

export default function AlbumList({
  collectionId,
  isCondensed,
}: AlbumListProps): JSX.Element {
  const [searchParams] = useSearchParams();
  const page = getPage(searchParams);
  const [albums, setAlbums] = useState<AlbumsResult>({
    albums: [],
    albumsCount: 0,
  });
  const albumPages = Math.max(Math.ceil(albums.albumsCount / pageSize), 1);
  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");
  const [searchQuery, setSearchQuery] = useState("");
  const { ref, isVisible } = useIntersectionObserver();
  const [assets, setAssets] = useState<AssetsResult>({
    assets: [],
    assetsCount: 0,
  });
  const [isAlbumsChecked, setIsAlbumsChecked] = useState(true);
  const [isAssetsChecked, setIsAssetsChecked] = useState(true);
  const albumsRefresh = useAlbumsRefresh(); // notifies us when new albums get created
  const assetsRefresh = useAssetsRefresh();

  const handleAlbumsAssetsChange = (checkboxType: string) => {
    if (checkboxType === "albums") {
      setIsAlbumsChecked(!isAlbumsChecked);
    } else if (checkboxType === "assets") {
      setIsAssetsChecked(!isAssetsChecked);
    }
  };

  useEffect(() => {
    if (!searchQuery) {
      setAssets({ assets: [], assetsCount: 0 });
      return;
    }

    let ignore = false;

    const fetchData = async (
      searchQuery: string,
      sortOrder: SortOrder = "ASC",
    ) => {
      try {
        if (collectionId) {
          const fetchedAssets = await getAssets(
            collectionId,
            undefined,
            searchQuery,
            25, // FIXME allow pagination for assets
            0,
            sortOrder,
          );
          if (!ignore) {
            setAssets(fetchedAssets);
          }
        }
      } catch (error) {
        console.error("Error fetching assets", error);
      }
    };

    fetchData(searchQuery, sortOrder);

    return () => {
      ignore = true;
    };
  }, [collectionId, searchQuery, sortOrder, assetsRefresh]);

  useEffect(() => {
    let ignore = false;
    (async () => {
      try {
        const fetchedAlbums = await getAlbums(
          collectionId,
          pageSize,
          (page - 1) * pageSize,
          searchQuery,
          sortOrder,
        );
        if (!ignore) {
          setAlbums(fetchedAlbums);
        }
      } catch (error) {
        console.error("Error fetching albums", error);
      }
    })();
    return () => {
      ignore = true;
    };
  }, [collectionId, searchQuery, sortOrder, page, albumsRefresh]);

  return (
    <div>
      <div tw="mb-6 sm:mt-24 z-10 sticky top-12">
        <MenuContainer highlight={!isVisible}>
          <div tw="flex space-x-4 sm:space-x-8">
            {isCondensed ? (
              <NeuButton to={`/collection/${collectionId}`}>
                <i className="mi-search" />
                {!isCondensed && <span> Search</span>}
              </NeuButton>
            ) : (
              <SearchInput
                collectionId={collectionId}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            )}
            {searchQuery && (
              <>
                <CheckboxRoundButton
                  id="albums"
                  label="Albums"
                  checked={isAlbumsChecked}
                  onChange={(id) => handleAlbumsAssetsChange(id)}
                />

                <CheckboxRoundButton
                  id="assets"
                  label="Assets"
                  checked={isAssetsChecked}
                  onChange={(id) => handleAlbumsAssetsChange(id)}
                />
              </>
            )}
            <NeuButton
              onClick={() => setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")}
            >
              <i className="mi-sort" />
              {!isCondensed && <span>Sort</span>}
            </NeuButton>
            <Link to={`/collection/${collectionId}/new-album`}>
              <NeuButton>
                <i className="mi-add" />{" "}
                {!isCondensed && <span>New album</span>}
              </NeuButton>
            </Link>
          </div>
        </MenuContainer>
      </div>
      {isAlbumsChecked && (
        <div>
          <span ref={ref} tw="label mb-4">
            Albums
          </span>
          <div tw="space-y-4">
            {albums.albums.length > 0 ? (
              albums.albums.map((a) => (
                <Suspense key={a.id} fallback={<AlbumLink album={a} />}>
                  <RichAlbumLink
                    album={a}
                    isCondensed={isCondensed}
                    pageNumber={page}
                  />
                </Suspense>
              ))
            ) : searchQuery ? (
              <NoMatchesMessage searchQuery={searchQuery} />
            ) : null}
          </div>
          <Pagination pages={albumPages} tw="mt-4" />
        </div>
      )}
      {searchQuery && isAssetsChecked && (
        <div className="mt-6">
          <span tw="label mb-2">Assets</span>
          <div tw="grid grid-cols-3 gap-2">
            {assets.assets.length > 0 ? (
              assets.assets.map((a) => (
                <div tw="flex" key={a.id}>
                  <AssetTile asset={a} />
                  <div tw="w-8 relative overflow-hidden">
                    <span tw="pl-4 absolute bottom-0 left-0 inline-block text-sm font-bold -rotate-90 origin-bottom-left whitespace-nowrap translate-x-6">
                      {a.name}
                    </span>
                  </div>
                </div>
              ))
            ) : searchQuery ? (
              <NoMatchesMessage searchQuery={searchQuery} />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

interface AlbumLinkProps {
  album: Album;
  pageNumber?: number;
  imageUrls?: string[];
  isCondensed?: boolean;
}

function AlbumLink(props: AlbumLinkProps): JSX.Element {
  const a = props.album;

  return (
    <Link
      key={a.id}
      to={`/collection/${a.collectionId}/a/${a.id}?page=${props.pageNumber}`}
      tw="overflow-hidden transition-all px-2 py-2 shadow-card hover:pr-6 hover:-mx-2 rounded relative block"
      style={{ height: props.isCondensed ? "3.5rem" : "6rem" }}
    >
      <div tw="absolute top-0 left-0 flex w-full">
        {props.imageUrls
          ?.slice(0, 5)
          .map((url) => (
            <img
              key={`${url + " key"}`}
              tw="min-h-full min-w-[50px] flex-grow flex-shrink"
              src={`${url}?w=350&h=350`}
              alt={a.name}
            />
          ))}
      </div>
      <div tw="absolute top-0 left-0 w-full h-full bg-gradient-to-br md:bg-gradient-to-r from-black to-transparent" />
      <div tw="relative text-bgbase">
        <h2
          tw="mt-0"
          style={{ fontSize: props.isCondensed ? "1.17rem" : undefined }}
        >
          {a.name}
        </h2>
      </div>
    </Link>
  );
}

function RichAlbumLink(props: AlbumLinkProps): JSX.Element {
  const { ref, isVisible } = useIntersectionObserver();

  return (
    <div ref={ref}>
      {isVisible ? (
        <Suspense
          fallback={
            <AlbumLink isCondensed={props.isCondensed} album={props.album} />
          }
        >
          <AlbumLinkAssetLoader
            isCondensed={props.isCondensed}
            album={props.album}
            pageNumber={props.pageNumber}
          />
        </Suspense>
      ) : (
        <AlbumLink isCondensed={props.isCondensed} album={props.album} />
      )}
    </div>
  );
}

function AlbumLinkAssetLoader(props: AlbumLinkProps): JSX.Element {
  const a = props.album;

  const album = useRecoilValue(
    albumSelector({ collectionId: a.collectionId!, albumId: a.id }),
  );
  const assets = useRecoilValue(
    albumAssetPreviewSelector({ collectionId: a.collectionId!, albumId: a.id }),
  );

  return (
    <AlbumLink
      isCondensed={props.isCondensed}
      album={album || a}
      imageUrls={assets.map((a) => a.url ?? "").filter((url) => url) || []}
      pageNumber={props.pageNumber}
    />
  );
}
