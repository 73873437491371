import "twin.macro";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  AlbumContext,
  CollectionContext,
  useAlbumHook,
  useAlbumsHook,
  useSelectedAssets,
  albumSelector,
  useAssetsRefresh,
} from "../../clients/apiHooks";
import { useIntersectionObserver } from "../../hooks";

import { Modal } from "../generic";
import ErrorBoundary from "../generic/ErrorBoundary";
import { MenuContainer, NeuButton } from "../generic/Neu";

import AssetTile from "./AssetTile";
import Uploads from "./Uploads";
import { UploadList } from "./UploadList";
import { ItemGroupContainer, TagItem } from "../tags/Items";
import { EditableTitle } from "./EditableTitle";
import { NoMatchesMessage, SortOrder } from "../collection/AlbumList";
import { Helmet } from "react-helmet";
import { AssetsResult, getAssets } from "../../clients/apiClient";
import { getPage, Pagination } from "../generic/Pagination";
import SearchInput from "../generic/SearchInput";

const pageSize = 20;

function AlbumView() {
  const { collectionId, albumId } = useParams<{
    collectionId: string;
    albumId: string;
  }>();
  const [searchParams] = useSearchParams();
  // FIXME: the way the query params work for pagination is kind of a mess because the album view still also has the album list
  const page = getPage(searchParams, "assetPage");
  const id = albumId;
  const [showUpload /*, setShowUpload*/] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { getSelectedAssets } = useSelectedAssets();
  const selectedAssets = getSelectedAssets();
  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");
  const navigate = useNavigate();

  const { patchAlbum } = useAlbumHook(collectionId!, albumId!);
  const { deleteAlbum } = useAlbumsHook(collectionId!);
  const album = useRecoilValue(
    albumSelector({ collectionId: collectionId!, albumId: albumId! }),
  );
  const [assets, setAssets] = useState<AssetsResult>({
    assets: [],
    assetsCount: 0,
  });

  const assetsRefresh = useAssetsRefresh();
  const pages = Math.max(Math.ceil(assets.assetsCount / pageSize), 1);

  const { ref, isVisible } = useIntersectionObserver();

  async function handleNewTag(update: { key: string; value: string }) {
    if (!album) {
      return;
    }
    const dataPayload = {
      tags: [...(album.tags || []), { key: update.key, value: update.value }],
    };
    await patchAlbum(dataPayload);
  }

  async function handleUpdateAlbumName(newAlbumName: string) {
    if (!album) {
      return;
    }
    const dataPayload = {
      name: newAlbumName,
    };
    await patchAlbum(dataPayload);
  }

  async function handleDeleteAlbum() {
    if (!album) {
      return;
    }
    await deleteAlbum(album.id);
    navigate(`/collection/${collectionId}`);
  }

  function handleUpdateTag(
    tag: { key: string; value: string },
    update: { key: string; value: string },
  ) {
    if (!album) {
      return;
    }
    const dataPayload = {
      tags: album.tags.map((t) =>
        t.key === tag.key && t.value === tag.value
          ? { key: update.key, value: update.value }
          : t,
      ),
    };
    patchAlbum(dataPayload);
  }

  function handleDeleteTag(tag?: { key: string; value: string }) {
    if (!album) {
      return;
    }
    const dataPayload = {
      tags: album.tags.filter(
        (t) => t.key !== tag?.key || t.value !== tag?.value,
      ),
    };
    patchAlbum(dataPayload);
  }

  useEffect(() => {
    let ignore = false;

    const fetchData = async (
      searchQuery: string,
      sortOrder: SortOrder = "ASC",
    ) => {
      try {
        if (collectionId) {
          const fetchedAssets = await getAssets(
            collectionId,
            albumId,
            searchQuery,
            pageSize,
            (page - 1) * pageSize,
            sortOrder,
          );
          if (!ignore) {
            setAssets(fetchedAssets);
          }
        }
      } catch (error) {
        console.error("Error fetching assets", error);
      }
    };

    fetchData(searchQuery, sortOrder);

    return () => {
      ignore = true;
    };
  }, [collectionId, albumId, searchQuery, sortOrder, assetsRefresh, page]);

  if (!album || !albumId || !collectionId) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<div>something went wrong loading albums...</div>}>
      <Helmet>
        <title>{album.name} | Exrepo</title>
      </Helmet>
      <CollectionContext.Provider value={collectionId ?? null}>
        {selectedAssets.length ? (
          <div>{selectedAssets.length} selected</div>
        ) : null}
        <AlbumContext.Provider value={id ?? null}>
          <div tw="max-w-[1440px]">
            {showUpload && (
              <Modal>
                <Uploads
                  albumId={album?.id}
                  collectionId={album?.collectionId}
                />
              </Modal>
            )}
            <div tw="sm:space-y-12">
              <div id="headerDiv" tw="flex flex-row justify-between">
                <div tw="w-full">
                  <span tw="label">Album</span>
                  <div tw="flex flex-row items-end justify-between w-full">
                    <h1>
                      <EditableTitle
                        title={album.name}
                        onUpdate={handleUpdateAlbumName}
                      />
                    </h1>
                    <NeuButton
                      tw="bg-danger"
                      icon="mi-delete"
                      disabled={!!assets.assets.length}
                      onClick={handleDeleteAlbum}
                    >
                      Delete Album
                    </NeuButton>
                  </div>
                </div>
                {/*
                <div>
                  <UploadList
                    current={false}
                    albumId={albumId}
                    collectionId={album?.collectionId}
                  />
                </div>
                */}
              </div>
              <div tw="space-y-2">
                <span tw="label mb-4">Tags</span>
                <ItemGroupContainer tw="-ml-1">
                  {album.labels?.map((label) => (
                    <TagItem
                      key={"#" + label}
                      tag={{ key: "#", value: label }}
                      onUpdate={handleUpdateTag}
                      onDelete={handleDeleteTag}
                    />
                  ))}
                  {album.tags?.map((t) => (
                    <TagItem
                      key={t.key + t.value}
                      tag={t}
                      onUpdate={handleUpdateTag}
                      onDelete={handleDeleteTag}
                    />
                  ))}
                  <TagItem key="new-tag-input" onCreate={handleNewTag} />
                </ItemGroupContainer>
              </div>
              <div>
                <span tw="label mb-2" ref={ref}>
                  Assets
                </span>
                <div tw="mb-4 sticky top-12 z-10">
                  <MenuContainer highlight={!isVisible}>
                    <div tw="flex space-x-4 sm:space-x-8">
                      <SearchInput
                        collectionId={collectionId}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                      />
                      <NeuButton
                        onClick={() =>
                          setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")
                        }
                      >
                        <i className="mi-sort" />
                        Sort
                      </NeuButton>
                      <Uploads
                        albumId={album?.id}
                        collectionId={album?.collectionId}
                      />
                    </div>
                    <UploadList
                      current
                      albumId={albumId}
                      collectionId={collectionId}
                    />
                  </MenuContainer>
                </div>
                <div tw="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 justify-items-center">
                  {assets.assets.length > 0 ? (
                    assets.assets.map((a) => (
                      <div tw="flex" key={a.id}>
                        <AssetTile asset={a} />
                        <div tw="w-8 relative overflow-hidden">
                          <span tw="pl-4 absolute bottom-0 left-0 inline-block text-sm font-bold -rotate-90 origin-bottom-left whitespace-nowrap translate-x-6">
                            {a.name}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : searchQuery ? (
                    <NoMatchesMessage searchQuery={searchQuery} />
                  ) : null}
                </div>
                <Pagination pages={pages} param="assetPage" tw="mt-4" />
              </div>
            </div>
          </div>
        </AlbumContext.Provider>
      </CollectionContext.Provider>
    </ErrorBoundary>
  );
}

export default React.memo(AlbumView);
