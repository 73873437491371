import React from "react";
import tw, { styled } from "twin.macro";

// TODO: create a full layout component

interface MarginWrapperProps {
  isCenter?: boolean;
  hasMenu?: boolean;
  children?: React.ReactNode;
}

export const MarginWrapper = styled.div((props: MarginWrapperProps) => [
  tw`absolute w-full transition-all ease-out overflow-y-auto h-screen overflow-x-visible`,
  tw`max-w-screen-xl`,
  props.isCenter ? tw`left-1/2 -translate-x-1/2` : tw`left-0 translate-x-0`,
  tw`pr-5 pl-3 pb-8 pt-4`,
]);
export const MenuCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all ease-out overflow-y-auto`,
  props.active ? tw`hidden sm:block min-w-[250px] w-[20%]` : tw`w-0 min-w-0`,
]);

export const MainCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all ease-out relative`,
  props.active
    ? tw`w-full sm:w-[80%]`
    : tw`hidden sm:block min-w-[250px] w-[20%]`,
]);
export const AlbumCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all ease-out relative`,
  props.active ? tw`w-full sm:w-[80%]` : tw`w-0`,
]);
