import "twin.macro";
import { useEffect, useRef, useState } from "react";
import {
  getCollectionTagsKeys,
  getCollectionTagsValues,
} from "../../clients/apiClient";

const Pill = ({
  text,
  onClick,
}: {
  text: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => (
  <span tw="inline-block border rounded border-[#a8a8a8] px-1">
    {text}
    <i className="mi-close" tw="ml-2" onClick={onClick} />
  </span>
);

interface InputProps {
  currentQuery: string;
  pillQueries: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveTerm: (index: number) => void;
  inputEl: React.RefObject<HTMLInputElement>;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = ({
  currentQuery,
  pillQueries,
  onChange,
  handleRemoveTerm,
  inputEl,
  onKeyPress,
  onKeyDown,
}: InputProps) => {
  return (
    <div
      tw="flex flex-wrap border bg-white rounded w-full gap-1 p-2"
      onClick={() => {
        inputEl.current?.focus();
      }}
    >
      <i className="mi-search" tw="flex items-center" />

      {pillQueries.map((query, index) => (
        <Pill
          key={index}
          text={query}
          onClick={() => handleRemoveTerm(index)}
        />
      ))}

      <input
        type="text"
        value={currentQuery}
        onChange={onChange}
        tw="bg-transparent border-none outline-none flex-grow"
        ref={inputEl}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

const getKey = (currentQuery: string): string | null => {
  if (currentQuery.includes("=") || currentQuery.includes("!=")) {
    return currentQuery.split(/=|!=/)[0];
  } else if (currentQuery.startsWith("#")) {
    return "#";
  } else {
    return "";
  }
};

const getPrefix = (currentQuery: string): string => {
  if (currentQuery.includes("=")) {
    return currentQuery.split("=")[1];
  } else if (currentQuery.includes("!=")) {
    return currentQuery.split("!=")[1];
  } else if (currentQuery.startsWith("#")) {
    return currentQuery.split("#")[1];
  } else {
    return "";
  }
};

const getQueryKeyWithOperator = (currentQuery: string): string => {
  if (currentQuery.includes("=")) {
    return currentQuery.substring(0, currentQuery.indexOf("=") + 1);
  } else if (currentQuery.includes("!=")) {
    return currentQuery.substring(0, currentQuery.indexOf("!=") + 2);
  } else {
    return "";
  }
};

function getSuggestionOptions(
  key: string | null,
  collectionValues: string[],
  operator: string[],
  collectionKeys: string[],
) {
  let suggestionOptions;
  if (key) {
    suggestionOptions = collectionValues;
  } else if (operator.length > 0) {
    suggestionOptions = operator;
  } else {
    suggestionOptions = collectionKeys;
  }
  return suggestionOptions;
}

function SearchInput({
  collectionId,
  setSearchQuery,
  searchQuery,
}: {
  collectionId: string;
  setSearchQuery: (newValue: string) => void;
  searchQuery: string;
}) {
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [pillQueries, setPillQueries] = useState<string[]>([]);
  const [isSuggestionOpen, setIsSuggestionOpen] = useState<boolean>(true);
  const [operator, setOperator] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const inputEl = useRef<HTMLInputElement>(null);

  const [collectionKeys, setCollectionKeys] = useState<{
    keys: string[];
  }>({
    keys: [],
  });

  const [collectionValues, setCollectionValues] = useState<{
    values: string[];
  }>({ values: [] });

  const key = getKey(currentQuery);
  const prefix = getPrefix(currentQuery);
  const queryKeyWithOperator = getQueryKeyWithOperator(currentQuery);

  useEffect(() => {
    const fetchCollectionData = async () => {
      if (currentQuery === "") {
        setCollectionKeys({ keys: [] });
        setCollectionValues({ values: [] });
      } else if (key && prefix) {
        const collectionValues = await getCollectionTagsValues(
          collectionId,
          key,
          prefix,
        );
        setCollectionValues(collectionValues);
      } else {
        const collectionKeys = await getCollectionTagsKeys(
          collectionId,
          currentQuery,
        );
        setCollectionKeys(collectionKeys);
      }
    };

    fetchCollectionData();
  }, [collectionId, searchQuery, currentQuery, key, prefix]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === " ") {
      event.preventDefault();
      if (currentQuery) {
        const currentSearch = currentQuery
          .split(" ")
          .filter((term) => term.trim() !== "");

        const uniquePillQueries = [
          ...new Set([...pillQueries, ...currentSearch]),
        ];
        setPillQueries(uniquePillQueries);

        setCurrentQuery("");
        setOperator([]);
      }
    }
  };

  const suggestionOptions = getSuggestionOptions(
    key,
    collectionValues.values,
    operator,
    collectionKeys.keys,
  );

  const handleRemoveTerm = (index: number) => {
    const newSearchTerms = pillQueries.toSpliced(index, 1);
    setPillQueries(newSearchTerms);
    setSearchQuery(newSearchTerms.join(" "));
  };

  const previousQuery = searchQuery.split(" ").slice(0, -1).join(" ");
  const previousQueryWithSpace = previousQuery ? previousQuery + " " : "";

  const handleSuggestionClick = (item: string) => {
    setOperator([]);
    if (key && prefix) {
      const newQuery = `${queryKeyWithOperator || key}${item}`;
      setCurrentQuery(`${newQuery}`);
      setSearchQuery(`${previousQueryWithSpace} ${newQuery}`);
      setIsSuggestionOpen(false);
    } else {
      if (operator.length > 0) {
        setCurrentQuery(`${currentQuery}${item}`);
        setSearchQuery(`${previousQueryWithSpace}${currentQuery}${item}`);
        setIsSuggestionOpen(false);
      } else {
        setCurrentQuery(`${item}`);
        setSearchQuery(`${previousQueryWithSpace}${item}`);
        setIsSuggestionOpen(true);
        setOperator(["=", "!="]);
      }
    }
    if (searchQuery === "") {
      setIsSuggestionOpen(false);
    }

    inputEl.current?.focus();
  };

  const handleOnkeyMove = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (suggestionOptions.length === 0) {
      return;
    }

    if (event.key === "ArrowDown") {
      if (
        selectedIndex === null ||
        selectedIndex < suggestionOptions.length - 1
      ) {
        setSelectedIndex((prevIndex) =>
          prevIndex !== null ? prevIndex + 1 : 0,
        );
      }
      return;
    }

    if (event.key === "ArrowUp") {
      if (selectedIndex === null || selectedIndex > 0) {
        setSelectedIndex((prevIndex) =>
          prevIndex !== null ? prevIndex - 1 : suggestionOptions.length - 1,
        );
      }
      return;
    }

    if (event.key === "Enter" && selectedIndex !== null) {
      handleSuggestionClick(suggestionOptions[selectedIndex]);
      setSelectedIndex(null);
      return;
    }
  };

  return (
    <div tw="flex flex-col w-96">
      <form
        tw="inline-block relative flex-shrink"
        onSubmit={(env) => env.preventDefault()}
      >
        <Input
          currentQuery={currentQuery}
          pillQueries={pillQueries}
          onChange={(event) => {
            setCurrentQuery(event.target.value);
            setSearchQuery([...pillQueries, event.target.value].join(" "));
            setIsSuggestionOpen(true);
          }}
          handleRemoveTerm={handleRemoveTerm}
          inputEl={inputEl}
          onKeyPress={handleKeyPress}
          onKeyDown={(event) => {
            handleOnkeyMove(event);
            if (event.key === "Backspace" && currentQuery === "") {
              handleRemoveTerm(pillQueries.length - 1);
              return;
            }
          }}
        />
      </form>

      {isSuggestionOpen && suggestionOptions.length > 0 && (
        <div tw="bg-white">
          {suggestionOptions.map((item, index) => {
            const isSelected = selectedIndex === index;

            return (
              <p
                tw=" cursor-pointer mt-0 p-2"
                onClick={() => {
                  handleSuggestionClick(item);
                }}
                key={item}
                className={`${isSelected ? "bg-grey" : "bg-white"}`}
              >
                {item}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchInput;
