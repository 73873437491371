import "twin.macro";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCollections, useWorkspace } from "../../clients/apiHooks";

import ResourceInput from "../generic/ResourceInput";
import { BackButton } from "../generic/Neu";
import { Helmet } from "react-helmet";
import { MainCol, MarginWrapper, MenuCol } from "../generic/Layout";
import CollectionList from "../navigation/CollectionList";
import ShareList from "../navigation/ShareList";
import ErrorBoundary from "../generic/ErrorBoundary";

function CollectionExistsErr(): JSX.Element {
  return (
    <div>
      A <b>collection</b> with that <b>name already exists</b> in your
      workspace.
    </div>
  );
}

function NewCollectionView(): JSX.Element {
  const navigate = useNavigate();
  const [duplicationErr, setDuplicationErr] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [collections /*, loading, err*/] = useWorkspace();
  const { createCollection } = useCollections();

  function changeHandler(val: string) {
    setDuplicationErr(
      !!collections &&
        !!collections.filter((c) => c.name === val.trim()).length,
    );
  }

  function submitHandler(val: string) {
    setCreateLoading(true);
    createCollection(val.trim())
      .then((c) => {
        setCreateLoading(false);
        navigate(`/collection/${c.id}`, { replace: true });
      })
      .catch((err) => {
        console.error(err);
        setCreateLoading(false);
      });
  }

  return (
    <div>
      <Helmet>
        <title>Exrepo</title>
      </Helmet>
      <ErrorBoundary>
        <div tw="max-w-screen-fhd flex mx-auto">
          <MenuCol active>
            <CollectionList />
            <ShareList />
          </MenuCol>
          <MainCol active>
            <MarginWrapper isCenter hasMenu>
              <div tw="p-1 flex flex-col gap-y-8 items-start">
                <BackButton />
                <div>
                  <span tw="label">New Collection</span>
                  <ResourceInput
                    tw="text-4xl sm:text-6xl"
                    placeholder="collection name"
                    onChange={changeHandler}
                    onSubmit={submitHandler}
                    submitDisabled={!!(duplicationErr || createLoading)}
                    error={duplicationErr ? <CollectionExistsErr /> : null}
                    cta="Create"
                  />
                </div>
                This will create a new collection in your workspace. You can
                edit settings and choose with whom to share it after its
                creation.
              </div>
            </MarginWrapper>
          </MainCol>
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default NewCollectionView;
