import "twin.macro";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import * as reactRouterDom from "react-router-dom";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";

import CollectionView from "./components/collection/CollectionView";
import CollectionList from "./components/navigation/CollectionList";
import LandingView from "./components/LandingView";
import LoginView from "./components/LoginView";
import { RecoilRoot } from "recoil";
import React, { Suspense } from "react";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { NeuButton } from "./components/generic/Neu";
import ShareList from "./components/navigation/ShareList";
import ShareView from "./components/share/ShareView";
import NewShareView from "./components/share/NewShareView";
import NewCollectionView from "./components/collection/NewCollectionView";

function App() {
  return (
    <RecoilRoot>
      <div className="App" tw="bg-bgbase h-full">
        <Router>
          <Routes>
            <Route path="/" element={<LandingView />} />
          </Routes>
          <header className="App-header" tw="flex flex-row min-w-min"></header>
          <Routes>
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
              ThirdPartyEmailPasswordPreBuiltUI,
            ])}
            <Route path="/tokenlogin" element={<LoginView />} />
            <Route
              path="/collection/"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <CollectionList />
                    <ShareList />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/new-collection"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <NewCollectionView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId/new-album"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <CollectionView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <CollectionView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId/a/:albumId"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <CollectionView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId/a/:albumId/s/:assetId"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <CollectionView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/share/:shareId"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <ShareView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/share/new-share"
              element={
                <PrivateRoute>
                  <Suspense fallback={<div />}>
                    <NewShareView />
                  </Suspense>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </RecoilRoot>
  );
}

function PrivateRoute({ children }: { children: JSX.Element }): JSX.Element {
  return (
    <div tw="relative">
      <LogOutButton tw="absolute top-4 right-4 z-50" />
      {children}
    </div>
  );
  // FIXME clean up token-based login stuff
  // const token = localStorage.getItem("token");
  // return token ? children : <Navigate to="/login" />;
}

function LogOutButton({ className }: { className?: string }): JSX.Element {
  const navigate = useNavigate();

  async function onLogout() {
    try {
      await signOut();
      navigate("/");
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <NeuButton onClick={onLogout} className={className}>
      Log out
    </NeuButton>
  );
}

export default App;
