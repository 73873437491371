import { Link, useMatch } from "react-router-dom";

export interface ListLinkProps {
  className?: string;
  name: string;
  href: string;
}

export function ListLink({
  className,
  href,
  name,
}: ListLinkProps): JSX.Element {
  const routeMatch = useMatch({
    path: href,
  });
  return (
    <div className={className}>
      <Link to={href}>
        <div>
          {routeMatch && ">"} {name}
        </div>
      </Link>
    </div>
  );
}
