import "twin.macro";
import React from "react";

import { useCallback, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";

import { AlbumWithAssets } from "../../clients/apiClient";
import { useAsset /* useSelectedAssets */ } from "../../clients/apiHooks";

import { Icon } from "../generic";
import ErrorBoundary from "../generic/ErrorBoundary";
import { DocumentView } from "./DocumentView";
import { BackButton, NeuButton } from "../generic/Neu";

import { TagItem, ItemGroupContainer } from "../tags/Items";
import { EditableTitle } from "./EditableTitle";
import { Helmet } from "react-helmet";

export interface AssetDetailsProps {
  id: string;
  albumId?: string;
  collectionId?: string;
  album?: AlbumWithAssets | null;
  className?: string;
  onSelect: () => void;
  onClose: () => void;
}

function AssetDetails(props: AssetDetailsProps): JSX.Element {
  /*
  const { toggleSelectAsset, getAssetState } = useSelectedAssets();
  const assetState = getAssetState(props.id);
  */
  let pdfUrl: string | boolean = false;

  const { asset, deleteAsset, updateAssetMetadata } = useAsset({
    assetId: props.id,
    albumId: props.albumId,
    collectionId: props.collectionId,
  });

  const handleDelete = () =>
    deleteAsset().then(() => {
      props.onClose();
    });

  const navigate = useNavigate();
  const handleKeyPress = useCallback(
    (event) => {
      const album = props.album;
      if (album?.assets?.length) {
        const currIdx = album?.assets?.findIndex(
          (ast: any) => ast.id === props.id,
        );
        const prevIdx = currIdx === 0 ? album.assets.length - 1 : currIdx - 1;
        const nextIdx = currIdx === album.assets.length - 1 ? 0 : currIdx + 1;
        const prev = album.assets[prevIdx];
        const next = album.assets[nextIdx];
        event.key === "ArrowRight" &&
          navigate(
            `/collection/${album.collectionId}/a/${album.id}/s/${next.id}`,
          );
        event.key === "ArrowLeft" &&
          navigate(
            `/collection/${album.collectionId}/a/${album.id}/s/${prev.id}`,
          );
      }
    },
    [props, navigate],
  );

  useEffect(() => {
    // FIXME this is also triggered when moving a cursor inside an input...
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  if (!asset) {
    return <div />;
  }

  if (asset.url && asset.url.match(/(\.pdf)$/i)) {
    pdfUrl = asset.url.replace("/i/", "/o/");
  }

  async function handleNewTag(update: { key: string; value: string }) {
    if (!asset) {
      return;
    }
    const dataPayload = {
      tags: [...asset.tags, { key: update.key, value: update.value }],
    };
    await updateAssetMetadata(dataPayload);
  }

  async function handleUpdateName(title: string) {
    if (!asset) {
      return;
    }
    const dataPayload = {
      name: title,
    };
    await updateAssetMetadata(dataPayload);
  }

  function handleUpdateTag(
    tag: { key: string; value: string },
    update: { key: string; value: string },
  ) {
    if (!asset) {
      return;
    }
    const dataPayload = {
      tags: asset.tags.map((t) =>
        t.key === tag.key && t.value === tag.value
          ? { key: update.key, value: update.value }
          : t,
      ),
    };
    updateAssetMetadata(dataPayload);
  }

  function handleDeleteTag(tag?: { key: string; value: string }) {
    if (!asset) {
      return;
    }
    const dataPayload = {
      tags: asset.tags.filter(
        (t) => t.key !== tag?.key || t.value !== tag?.value,
      ),
    };
    updateAssetMetadata(dataPayload);
  }

  return (
    <ErrorBoundary
      fallback={<div>something went wrong loading the asset...</div>}
    >
      <Helmet>
        <title>{asset.name} | Exrepo</title>
      </Helmet>
      <div className={props.className} tw="flex flex-col sm:flex-row h-full">
        <div tw="flex-grow h-full order-2 sm:order-1 bg-grey-lightest bg-opacity-95 relative justify-center overflow-auto">
          {pdfUrl ? (
            <div tw="items-center flex flex-col">
              <DocumentView fullUrl={pdfUrl} />
            </div>
          ) : (
            <img
              src={`/i/${asset.collection_id}/${asset.album_id}/${asset.id}/${asset.id}?w=1000&h=1000`}
              alt={asset.id}
              tw="max-h-[98%] absolute top-1/2 left-1/2 transform translate-x--1/2 translate-y--1/2"
            />
          )}
        </div>
        <div tw="flex-none order-1 sm:order-2 w-full sm:w-1/2 xl:w-1/3 bg-bgbase px-3 py-4 space-y-2 sm:space-y-16 overflow-auto">
          <BackButton tw="mb-6" />
          <div>
            <span tw="label mb-4">Name</span>
            <h2 tw="break-all">
              {" "}
              <EditableTitle title={asset.name} onUpdate={handleUpdateName} />
            </h2>
          </div>

          <div>
            <span tw="label mb-4">Tags</span>
            <ItemGroupContainer>
              {asset.labels.map((l) => (
                <TagItem
                  key={"#" + l}
                  tag={{ key: "#", value: l }}
                  onUpdate={handleUpdateTag}
                  onDelete={handleDeleteTag}
                />
              ))}
              {asset.tags.map((t) => (
                <TagItem
                  key={t.key + t.value}
                  tag={t}
                  onUpdate={handleUpdateTag}
                  onDelete={handleDeleteTag}
                />
              ))}
              <TagItem key="new-tag-input" onCreate={handleNewTag} />
              {asset.labels?.length || asset.tags?.length ? (
                <span tw="ml-0" />
              ) : null}
            </ItemGroupContainer>
            {asset.album_labels?.length || asset.album_tags?.length ? (
              <>
                <span tw="label font-normal mt-6 mb-4">
                  Tags inherited from album
                </span>
                <ItemGroupContainer>
                  {asset.album_labels?.map((l) => (
                    <TagItem key={"#" + l} tag={{ key: "#", value: l }} />
                  ))}
                  {asset.album_tags?.map((t: any) => (
                    <TagItem
                      key={t[0] + t[1]}
                      tag={{ key: t[0], value: t[1] }}
                    />
                  ))}
                </ItemGroupContainer>
              </>
            ) : null}
          </div>

          {false && (
            <div>
              <span tw="label">
                <Icon t="link-alt" /> Shares
              </span>
              <div> [website] </div>
            </div>
          )}

          <div tw="flex space-x-8">
            <a href={asset.download_path} download>
              <NeuButton icon="mi-download">Download</NeuButton>
            </a>
            <NeuButton
              icon="mi-delete"
              tw="bg-[#ce4040]"
              onClick={handleDelete}
            >
              Delete
            </NeuButton>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default memo(AssetDetails);
