import { createContext, useContext, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Asset,
  Collection,
  Workspace,
  uploadFile,
  Album,
  getWorkspace,
  createAlbum,
  deleteAsset,
  patchAlbum,
  updateAssetMetadata,
  PatchAlbumParameters,
  createCollection,
  CollectionBase,
  patchCollection,
  PatchCollectionParameters,
  getAlbum,
  deleteAlbum,
  getAsset,
  Share,
  createShare,
  getShares,
  getAssets,
  PatchShareParameters,
  patchShare,
  inviteToShare,
  InviteToShareParameters,
} from "./apiClient";

import {
  activeUploads,
  FileUpload,
  selectedAssetDictionary,
  UploadStatus,
} from "../atoms";
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";

export function useWorkspace(): [Workspace | null, boolean, boolean] {
  // TODO: "The" react way would be Suspense and ErrorBoundary..
  const loadable = useRecoilValueLoadable(workspaceAtom);
  switch (loadable.state) {
    case "hasValue":
      return [loadable.contents, false, false];
    case "loading":
      return [null, true, false];
    case "hasError":
      return [null, false, true];
  }
}

export function useCollections() {
  const [, setWorkspace] = useRecoilState(workspaceAtom);

  const _refreshWorkspace = async () => {
    const workspace = await getWorkspace();
    setWorkspace(workspace);
  };

  const _createCollection = async (name: string): Promise<CollectionBase> => {
    const collection = await createCollection(name);
    _refreshWorkspace();
    return collection;
  };

  return {
    refreshWorkspace: _refreshWorkspace,
    createCollection: _createCollection,
  };
}

export function useCollection(collectionId?: string) {
  const collection = useRecoilValue(collectionSelector(collectionId));
  const refreshCollection = useRecoilRefresher_UNSTABLE(
    collectionSelector(collectionId),
  );

  const _patchCollection = async (newValues: PatchCollectionParameters) => {
    patchCollection(newValues.name, newValues.collectionId)
      .then(() => {
        refreshCollection();
      })
      .catch((err) => {
        console.error(err);
        refreshCollection();
      });
  };

  return { patchCollection: _patchCollection, collection: collection };
}

export const CollectionContext = createContext<string | null>(null);
export const AlbumContext = createContext<string | null>(null);

export function useSelectedAssets() {
  const [selectedAssets, setSelectedAssets] = useRecoilState(
    selectedAssetDictionary,
  );

  function toggleSelectAsset(assetId: string, currentState: boolean) {
    setSelectedAssets((prevState) => ({
      ...prevState,
      [assetId]: { isSelected: !currentState },
    }));
  }

  function getSelectedAssets() {
    return Object.keys(selectedAssets).filter(
      (id) => selectedAssets[id]?.isSelected,
    );
  }
  function getAssetState(assetId: string) {
    if (selectedAssets[assetId]) {
      return selectedAssets[assetId];
    }
    return { isSelected: false };
  }
  return {
    selectedAssets,
    getSelectedAssets,
    getAssetState,
    toggleSelectAsset,
  };
}

export function useUploads(albumId: string, collectionId: string) {
  const [atomFiles, setAtomFiles] = useRecoilState(activeUploads);
  const { refreshAssets } = useAssets();

  function handleFileSelect(evt: React.FormEvent<HTMLInputElement>) {
    const fileInput = Array.from(evt.currentTarget.files || []);
    const newFiles: FileUpload[] = fileInput
      .filter(
        (f: File) =>
          !atomFiles.some(
            (enquedFile) =>
              enquedFile.state !== UploadStatus.FAILURE &&
              enquedFile.file.name === f.name,
          ),
      )
      .map((f: File) => ({
        file: f,
        albumId: albumId,
        collectionId: collectionId,
        state: UploadStatus.IN_PROGRESS,
        id: uuidv4(),
      }));

    setAtomFiles(atomFiles.concat(newFiles));

    newFiles.forEach((fileUpload) => {
      uploadFile(fileUpload)
        .then((response) => {
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.SUCCESS },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .catch((error) => {
          console.error(error);
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.FAILURE },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .finally(async () => {
          refreshAssets();
        });
    });
  }

  const removeFile = useCallback(
    (filename: string) => {
      setAtomFiles((atomFiles) =>
        atomFiles.filter((fileUpload) => fileUpload.file.name !== filename),
      );
    },
    [setAtomFiles],
  );

  return { atomFiles, handleFileSelect, removeFile };
}

const albumsRefreshAtom = atom({
  key: "AlbumsRefresh",
  default: 0,
});

export function useAlbumsHook(collectionId: string) {
  const refreshAlbums = useRecoilCallback(({ set }) => () => {
    set(albumsRefreshAtom, (value) => value + 1);
  });

  const _createAlbum = async (name: string): Promise<Album> => {
    const album = await createAlbum(collectionId, name);
    refreshAlbums();
    return album;
  };

  const _deleteAlbum = async (albumId: string) => {
    await deleteAlbum(collectionId, albumId);
    refreshAlbums();
  };

  return {
    createAlbum: _createAlbum,
    deleteAlbum: _deleteAlbum,
    refreshAlbums,
  };
}

export function useAlbumsRefresh(): unknown {
  return useRecoilValue(albumsRefreshAtom);
}

export function useAlbumHook(collectionId: string, albumId: string) {
  const refreshAlbum = useRecoilRefresher_UNSTABLE(
    albumSelector({ collectionId, albumId }),
  );
  const { refreshAlbums } = useAlbumsHook(collectionId);
  const { refreshAssets } = useAssets();

  const _patchAlbum = async (newValues: PatchAlbumParameters) => {
    patchAlbum(collectionId, albumId, newValues)
      .then(() => {
        refreshAlbum();
        refreshAlbums();
        refreshAssets();
      })
      .catch((err) => {
        console.error(err);
        refreshAlbum();
        refreshAlbums();
        refreshAssets();
      });
  };

  return { patchAlbum: _patchAlbum };
}

/*
  These atoms (workspace, albums and assets) correspond to our backend entities and should follow those definitions
  closely.
  Everything else we need (for convenience in the UI) should be derived selectors from those.
*/

export const workspaceAtom = atom<Workspace | null>({
  key: "Workspace",
  default: selector<Workspace | null>({
    key: "Workspace/Default",
    get: async () => {
      return await getWorkspace();
    },
  }),
});

const sharesAtom = atom<Share[] | null>({
  key: "Shares",
  default: selector<Share[] | null>({
    key: "Shares/Default",
    get: async () => {
      return await getShares();
    },
  }),
});

export const albumSelector = selectorFamily<
  Album,
  { collectionId: string; albumId: string }
>({
  key: "AlbumsById",
  get:
    ({ collectionId, albumId }) =>
    () => {
      return getAlbum(collectionId, albumId);
    },
});

export const albumAssetPreviewSelector = selectorFamily<
  Asset[],
  { collectionId: string; albumId: string }
>({
  key: "AlbumAssetsFirstPage",
  get:
    ({ collectionId, albumId }) =>
    async () => {
      const result = await getAssets(
        collectionId,
        albumId,
        undefined,
        5,
        0,
        "DESC",
      );
      return result.assets;
    },
});

function useAssets() {
  const refreshAssets = useRecoilCallback(({ set }) => () => {
    set(assetsRefreshAtom, (value) => value + 1);
  });

  return { refreshAssets };
}

export function useAsset(params: {
  assetId: string;
  albumId?: string;
  collectionId?: string;
}) {
  const ctxAlbumId = useContext(AlbumContext);
  const ctxCollectionId = useContext(CollectionContext);

  const assetId = params.assetId;
  const collectionId = params.collectionId || ctxCollectionId;
  const albumId = params.albumId || ctxAlbumId;

  const asset = useRecoilValue(
    assetSelector({
      collectionId: collectionId || "",
      albumId: albumId || "",
      assetId: params.assetId,
    }),
  );

  const { refreshAssets } = useAssets();

  const _deleteAsset = useCallback(async () => {
    if (!collectionId || !albumId) {
      return;
    }
    await deleteAsset(collectionId, albumId, assetId);
    refreshAssets();
  }, [collectionId, albumId, assetId, refreshAssets]);

  const _updateAssetMetadata = useCallback(
    async (metadata) => {
      if (!collectionId || !albumId) {
        return;
      }
      await updateAssetMetadata(metadata, collectionId, albumId, assetId);
      refreshAssets();
    },
    [collectionId, albumId, assetId, refreshAssets],
  );

  return {
    asset,
    deleteAsset: _deleteAsset,
    updateAssetMetadata: _updateAssetMetadata,
  };
}

const assetsRefreshAtom = atom({
  key: "AssetsRefresh",
  default: 0,
});

export function useAssetsRefresh(): unknown {
  return useRecoilValue(assetsRefreshAtom);
}

export const assetSelector = selectorFamily<
  Asset | null,
  { collectionId: string; albumId: string; assetId: string }
>({
  key: "SingleAssetSelector",
  get:
    ({ collectionId, albumId, assetId }) =>
    async ({ get }) => {
      const asset = await getAsset(collectionId, albumId, assetId);
      return asset;
    },
});

export const collectionSelector = selectorFamily<
  Collection | null,
  string | null | undefined
>({
  key: "Collection",
  get:
    (collectionId) =>
    async ({ get }) => {
      const collections = get(workspaceAtom);
      const collection = collections?.filter(
        (collection) => collection.id === collectionId,
      );
      if (!collectionId || !collection?.length) {
        return null;
      }

      return {
        name: collection[0].name,
        id: collectionId,
      };
    },
});

const shareSelector = selectorFamily<Share | null, string | null | undefined>({
  key: "Share",
  get:
    (shareId) =>
    async ({ get }) => {
      const shares = get(sharesAtom);
      const share = shares?.filter((share) => share.id === shareId);
      if (!shareId || !share?.length) {
        return null;
      }

      return {
        name: share[0].name,
        id: shareId,
      };
    },
});

export function useShares() {
  const [shares, setShares] = useRecoilState(sharesAtom);

  const _refreshShares = async () => {
    const shares = await getShares();
    setShares(shares);
  };

  const _createShare = async (name: string): Promise<Share> => {
    const share = await createShare(name);
    _refreshShares();
    return share;
  };

  return {
    createShare: _createShare,
    refreshShares: _refreshShares,
    shares,
  };
}

export function useShare(shareId?: string) {
  const share = useRecoilValue(shareSelector(shareId));
  const refreshShare = useRecoilRefresher_UNSTABLE(collectionSelector(shareId));
  const { refreshShares } = useShares();

  const _patchShare = async (payload: PatchShareParameters) => {
    patchShare(payload.name, payload.shareId)
      .then(() => {
        refreshShare();
        refreshShares();
      })
      .catch((err) => {
        console.error(err);
        refreshShare();
        refreshShares();
      });
  };

  const _inviteToShare = async (payload: InviteToShareParameters) => {
    inviteToShare(payload.emailStr, payload.shareId)
      .then(() => {
        refreshShare();
        refreshShares();
      })
      .catch((err) => {
        console.error(err);
        refreshShare();
        refreshShares();
      });
  };

  return { inviteToShare: _inviteToShare, patchShare: _patchShare, share };
}
